import { Component, effect, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { IPatient } from 'src/app/interfaces/IPatient';
import { ISearchPatient } from 'src/app/interfaces/ISearchPatient';
import { AllergyService } from 'src/app/services/allergy.service';
import { CarePlanService } from 'src/app/services/carePlan.service';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { CurrentProblemsService } from 'src/app/services/currentProblems.service';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { ImagingService } from 'src/app/services/imaging.service';
import { InitialAssessmentService } from 'src/app/services/initialAssessment.service';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { MedicalAlertService } from 'src/app/services/medicalAlert.service';
import { MedicalDeviceService } from 'src/app/services/medicalDevice.service';
import { MedicalHistoryService } from 'src/app/services/medicalHistory.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import { PatientService } from 'src/app/services/patient.service';
import { PregnancyHistoryService } from 'src/app/services/pregnancyHistory.service';
import { PregnancyOutcomeService } from 'src/app/services/pregnancyOutcome.service';
import { PregnancyStatusService } from 'src/app/services/pregnancyStatus.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ResolvedProblemsService } from 'src/app/services/resolvedProblems.service';
import { SocialHistoryService } from 'src/app/services/socialHistory.service';
import { SupportingDocumentsService } from 'src/app/services/supportingDocuments.service';
import { TenantSettingsService } from 'src/app/services/tenantSettings.service';
import { TokenService } from 'src/app/services/token.service';
import { TravelHistoryService } from 'src/app/services/travelHistory.service';
import { VaccinationService } from 'src/app/services/vaccination.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css'],
})
export class PatientsComponent {
  // @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  isPreRegistration: boolean = false;

  public isLoading: boolean = false;

  fnField = '';
  fnSort = 0;

  public documentID?: string;
  public firstName?: string;
  public lastName?: string;
  public dateOfBirth?: Date;

  // DUPLICATE VARIABLES FOR SEARCH FIELDS
  // SET THE VALUE ON PRESS OF BUTTON "SEARCH"
  //
  // IF WE DON'T HAVE THOSE THE SEARCH WILL GET
  // THE VALUES FROM THE SEARCH FORM WITHOUT THE
  // NEED TO PRESS THE SEARCH BUTTON
  // AND THE PAGINATION WILL BREAK
  public documentValue?: string;
  public firstNameValue?: string;
  public lastNameValue?: string;
  public dateOfBirthValue?: Date;

  public data: MatTableDataSource<IPatient> = new MatTableDataSource<IPatient>(
    []
  );

  public totalRecords: number = 0;

  public displayedColumns: string[] = [
    'documentNumber',
    'firstName',
    'lastName',
    'dateOfBirth',
    'actions',
  ];

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
  }

  constructor(
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly allergyService: AllergyService,
    private readonly vaccinationService: VaccinationService,
    private readonly medicalAlertService: MedicalAlertService,
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly procedureService: ProcedureService,
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly resolvedProblemsService: ResolvedProblemsService,
    private readonly pregnancyHistoryService: PregnancyHistoryService,
    private readonly pregnancyOutcomeService: PregnancyOutcomeService,
    private readonly pregnancyStatusService: PregnancyStatusService,
    private readonly carePlanService: CarePlanService,
    private readonly socialHistoryService: SocialHistoryService,
    private readonly initialAssessmentService: InitialAssessmentService,
    private readonly travelHistoryService: TravelHistoryService,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly pastMedicationService: PastMedicationService,
    private readonly imagingService: ImagingService,
    private readonly labService: LaboratoryFileService,
    private readonly supportingDocumentsService: SupportingDocumentsService,
    private readonly hysteroscopyFileService: HysteroscopyFileService,
    private readonly vitalSignService: VitalSignService,
    private readonly keycloakService: KeycloakService,
    private readonly tokenService: TokenService,
    private readonly tenantService: TenantSettingsService
  ) {
    effect(() => {
      this.isPreRegistration =
        this.tenantService.tenantSettingsSignal().includePreRegistration;
    });
  }

  registerPatient() {
    this.router.navigate(['/ehr/register-patient']);
  }

  clearPatientsForm() {
    this.documentID = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.dateOfBirth = undefined;
    this.data = new MatTableDataSource<IPatient>([]);
    this.data.paginator = this.paginator;
  }

  // RESET DATA  WHEN ACCESSING A PATIENT
  clearDataFromServices() {
    this.allergyService.allergyDataSub.next({});
    this.vaccinationService.vaccinationDataSub.next([]);
    this.medicalAlertService.medicalAlertDataSub.next([]);
    this.medicalHistoryService.medicalHistoryDataSub.next([]);
    this.procedureService.procedureDataSub.next({});
    this.medicalDeviceService.medicalDeviceDataSub.next({});
    this.currentProblemsService.currentProblemDataSub.next({});
    this.resolvedProblemsService.resolvedProblemDataSub.next([]);
    this.pregnancyHistoryService.pregnancyHistoryDataSub.next([]);
    this.pregnancyOutcomeService.pregnancyOutcomeDataSub.next([]);
    this.pregnancyStatusService.pregnancyStatusDataSub.next([]);
    this.carePlanService.carePlanDataSub.next([]);
    this.socialHistoryService.socialHistoryDataSub.next([]);
    this.initialAssessmentService.initialAssessmentDataSub.next([]);
    this.travelHistoryService.travelHistoryDataSub.next([]);
    this.currentMedicationService.currentMedicationDataSub.next({});
    this.pastMedicationService.pastMedicationDataSub.next([]);
    this.imagingService.imagingDataSub.next([]);
    this.labService.laboratoryDataSub.next([]);
    this.supportingDocumentsService.supportingDocumentDataSub.next([]);
    this.hysteroscopyFileService.hysteroscopyDataSub.next([]);
    this.vitalSignService.vitalSignDataSub.next([]);
  }

  onRowClicked(row: IPatient) {
    // RESET DATA  BEFORE ACCESSING A PATIENT
    this.clearDataFromServices();

    this.router.navigate(['/ehr/profile'], {
      queryParams: {
        patientID: row?.id,
      },
    });
  }

  async pageChange() {
    const patientObj: ISearchPatient = {};
    if (this?.documentValue) patientObj.documentNumber = this.documentValue;
    if (this?.firstNameValue) patientObj.firstName = this.firstNameValue;
    if (this?.lastNameValue) patientObj.lastName = this.lastNameValue;
    if (this?.dateOfBirthValue)
      patientObj.dateOfBirth = this.dateOfBirthValue!.toString();

    if (this.fnField && this.fnField != '' && this.fnSort) {
      patientObj.sortBy = this.fnField;
      patientObj.sortDirection = this.fnSort == 1;
    }

    this.isLoading = true;
    await this.patientService
      .getPatients(
        patientObj,
        this.paginator.pageSize,
        this.paginator.pageIndex + 1
      )
      .subscribe({
        next: (d) => {
          this.data = new MatTableDataSource(d?.data?.map((d: IPatient) => d));

          this.totalRecords = d.totalRecords || 0;

          this.isLoading = false;
        },
        error: (err) => {},
      });
  }

  async searchPatientsForm() {
    this.paginator.pageIndex = 0;

    this.documentValue = this.documentID?.trim();
    this.firstNameValue = this.firstName?.trim();
    this.lastNameValue = this.lastName?.trim();
    this.dateOfBirthValue = this.dateOfBirth;

    this.pageChange();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  sortFn(field: string) {
    field == this.fnField
      ? (this.fnSort = (this.fnSort + 1) % 3)
      : (this.fnSort = 1);

    this.fnField = field;

    this.searchPatientsForm();
  }
}
