import { BooleanInput } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import {
  IAssignedDoctor,
  IPatientDetailed,
} from 'src/app/interfaces/Detailed/IPatientDetailed';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { CanLeaveGuard } from 'src/app/guards/can-leave.guard';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from 'src/app/services/token.service';
import { DynamicQuestionnaireService } from 'src/app/services/dynamicQuestionnaire.service';
import { DynamicQuestionnaireComponent } from '../components/inital-assessment-amen-section/dynamic-questionnaire/dynamic-questionnaire.component';
import { TenantSettingsService } from 'src/app/services/tenantSettings.service';

@Component({
  selector: 'app-patients-header',
  templateUrl: './patients-header.component.html',
  styleUrls: ['./patients-header.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientsHeaderComponent {
  @ViewChild(DynamicQuestionnaireComponent)
  dynamicQuestionnaire?: DynamicQuestionnaireComponent;
  @Input() hasUnsavedQuestionnaireChanges: boolean = false;
  @Input() episodeOfCare: boolean = false;
  @Input() isPreview: boolean = false;
  @Output() isPreviewChange = new EventEmitter<boolean>();

  public patientInfo?: IPatientDetailed;

  public patientLoading?: boolean = false;

  private destroy$ = new Subject<void>();

  public isEOCloading: BooleanInput = false;

  public previewValid: Boolean = false;
  public notes: string = '';
  public potentialDiagnosis?: number;

  public avatarImageUrl?: any;

  public isPilotStudy: boolean = false;
  public isReadOnly: boolean = false;
  public showPatientInformation: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private canLeaveGuard: CanLeaveGuard,
    private readonly translate: TranslateService,
    private readonly questionnaireService: DynamicQuestionnaireService,
    private cdr: ChangeDetectorRef,
    private readonly tenantService: TenantSettingsService
  ) {}

  // triggerChangeDetection() {
  //   console.log('triggerChangeDetection');
  //   this.cdr.markForCheck();
  // }

  ngOnInit() {
    this.eocService.showPatientInformationSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showInfo: boolean) => {
        this.showPatientInformation = showInfo;
      });

    this.eocService.previewValidSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Boolean) => {
        this.previewValid = data;
      });

    this.eocService.noteSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: string) => {
        this.notes = data;
      });

    this.eocService.potentialDiagnosisSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data?: number) => {
        this.potentialDiagnosis = data;
      });

    this.patientService.patientLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data?: boolean) => {
        this.patientLoading = data;
      });

    this.isPilotStudy = this.tenantService.tenantSettingsSignal().isPilotStudy;

    this.patientService.patientInfoSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data?: IPatientDetailed) => {
        this.avatarImageUrl = undefined;
        this.patientInfo = data;

        if (this.patientInfo && this.patientInfo.id) {
          if (Object.keys(this.patientInfo).length !== 0) {
            this.patientService
              .getProfilePicture(this.patientInfo.id.toString())
              .subscribe({
                next: (blob?: Blob) => {
                  if (blob) {
                    const reader = new FileReader();
                    reader.onload = () => {
                      this.avatarImageUrl = reader.result;
                    };
                    reader.readAsDataURL(blob);
                  }
                },
                error: (error) => {
                  this.avatarImageUrl = undefined;
                  console.error('Error fetching profile picture:', error);
                },
              });
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  calculatePatientBMI(
    weight: number | undefined,
    height: number | undefined
  ): string {
    if (weight && height && height !== 0) {
      const bmi = (weight / Math.pow(height / 100, 2)).toFixed(2);
      return bmi.toString();
    } else {
      return 'N/A';
    }
  }
  goToPatients() {
    this.router.navigate(['/ehr/allPatients']);
  }

  loadPreview() {
    if (this.hasUnsavedQuestionnaireChanges) {
      Swal.fire({
        text: `${this.translate.instant(
          'You have unsaved changes in the questionnaire. Please submit to continue.'
        )}`,
        icon: 'warning',
        showConfirmButton: true,
      });
      return;
    }
    this.isPreview = true;
    this.isPreviewChange.emit(true);
  }

  unloadPreview() {
    this.isPreview = false;
    this.isPreviewChange.emit(false);
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) &&
      (this.router.url.includes('inPatientView') ||
        this.router.url.includes('hysteroscopy-examination'))
    );
  }

  goToProfile() {
    Swal.fire({
      text: `${this.translate.instant(
        "Are you sure that you want to view the patient's profile? This visit will remain as draft."
      )}`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            patientID: this.patientInfo?.id,
          },
        };
        this.router.navigate(['/ehr/profile'], navigationExtras);
        Swal.fire({
          text: `${this.translate.instant('Visit saved as draft')}!`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ffd470',
          timer: 3000,
        });
      } else if (result.isDenied) {
        Swal.fire(`${this.translate.instant('Action canceled')}`, '', 'info');
      }
    });
  }

  // initial version
  // getPatientDetails() {
  //   // const dialogRef = this.dialog.open(DetailedProfileComponent, {
  //   //   width: 'auto',
  //   //   height: 'auto',
  //   //   data: { patient: this.patientInfo },
  //   // });
  //   // this.router.navigate(['/ehr/detailedProfile', patientID]);

  //   // this.router.navigate(['/ehr/detailedProfile'], {
  //   //   queryParams: {
  //   //     patientId: patientID,
  //   //   },
  //   // });
  // }

  //updated version_1
  // getPatientDetails() {
  //   const patientID = this.patientService.patientIDsignal();
  //   this.router.navigate(['/ehr/admission-profile'], {
  //     queryParams: {
  //       patientId: patientID,
  //       canNavigateBack: true,
  //     },
  //   });
  // }

  //updated version_2
  getPatientDetails() {
    this.showPatientInformation = !this.showPatientInformation;
    this.eocService.showPatientInformationSub.next(this.showPatientInformation);
  }

  addEpisodeOfCare() {
    Swal.fire({
      title: `<span style="font-size: 20px;">${this.translate.instant(
        'Create New Episode Of Care'
      )}</span>`,
      html: `${this.translate.instant(
        'Are you sure that you want to create a new Episode of Care for: '
      )} <span style="font-weight: bold;">${this.patientInfo?.firstName} ${
        this.patientInfo?.lastName
      }</span> ?`,

      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.isEOCloading = true;
        this.patientInfo?.id?.toString() &&
          this.eocService
            .createEpisodeOfCare(this.patientInfo?.id?.toString())
            .subscribe({
              next: (res) => {
                res?.data?.episodeOfCareId?.toString() &&
                  this.eocService.currentEocId.set(
                    res?.data?.episodeOfCareId?.toString()
                  );

                res?.data?.visitId?.toString() &&
                  this.eocService.currentVisitId.set(
                    res?.data?.visitId?.toString()
                  );

                this.isEOCloading = false;

                this.router.navigate([
                  '/ehr/episodeOfCare',
                  this.patientService.patientIDsignal(),
                  this.eocService.currentEocId(),
                  this.eocService.currentVisitId(),
                ]);

                Swal.fire({
                  text: `${this.translate.instant(
                    'Episode of Care created successfully'
                  )}!`,
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#0d9488',
                  timer: 3000,
                });
              },
              error: (err) => {
                Swal.fire({
                  text: `${this.translate.instant(
                    'Unable to create Episode of Care'
                  )}!`,
                  toast: true,
                  position: 'bottom-end',
                  showCancelButton: false,
                  showConfirmButton: false,
                  color: 'white',
                  background: '#ff6969',
                  timer: 3000,
                });
              },
            });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Episode of Care NOT created')}`,
          '',
          'info'
        );
      }
    });
  }

  calculateAge(dateString: string): number | undefined {
    const birthDate = new Date(dateString);
    const currentDate = new Date();

    const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      return yearsDiff - 1;
    } else {
      return yearsDiff;
    }
  }

  discardVisit() {
    Swal.fire({
      text: `${this.translate.instant(
        'Are you sure that you want to discard this visit?'
      )}`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.eocService
          .deleteVisit(this.eocService.currentVisitId())
          .subscribe({
            next: () => {
              this.dynamicQuestionnaire?.formGroup.markAsPristine();
              this.questionnaireService.setUnsavedChanges(false);
              if (this.isInpatientView()) {
                this.patientService.patientIDsignal.set('');
                this.patientService.patientInfoSub.next({});
                this.eocService.currentVisitId.set('');
                this.eocService.currentEocId.set('');

                const originalCanDeactivate = this.canLeaveGuard.canDeactivate;
                this.canLeaveGuard.canDeactivate = () => true;

                this.isPilotStudy
                  ? this.router
                      .navigate(['/ehr/allPatientsPilotStudy'])
                      .then(() => {
                        this.canLeaveGuard.canDeactivate =
                          originalCanDeactivate;
                      })
                  : this.router.navigate(['/ehr/inPatients']).then(() => {
                      this.canLeaveGuard.canDeactivate = originalCanDeactivate;
                    });
              } else {
                const originalCanDeactivate = this.canLeaveGuard.canDeactivate;
                this.canLeaveGuard.canDeactivate = () => true;
                this.router
                  .navigate(['/ehr/profile'], {
                    queryParams: {
                      patientID: this.patientService.patientIDsignal(),
                    },
                  })
                  .then(() => {
                    this.canLeaveGuard.canDeactivate = originalCanDeactivate;
                  });
              }

              Swal.fire({
                text: `${this.translate.instant(
                  'Visit was deleted successfully'
                )}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#0d9488',
                timer: 3000,
              });
            },
            error: () => {
              Swal.fire({
                text: `${this.translate.instant('Unable to delete visit')}!`,
                toast: true,
                position: 'bottom-end',
                showCancelButton: false,
                showConfirmButton: false,
                color: 'white',
                background: '#ff6969',
                timer: 3000,
              });
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Changes are not saved')}!`,
          '',
          'info'
        );
      }
    });
  }

  saveVisit() {
    Swal.fire({
      text: `${this.translate.instant(
        'Are you sure that you want to save this visit?'
      )}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.eocService.saveVisit().subscribe({
          next: (response) => {
            if (this.isInpatientView()) {
              this.patientService.patientIDsignal.set('');
              this.patientService.patientInfoSub.next({});
              this.eocService.currentVisitId.set('');
              this.eocService.currentEocId.set('');

              const originalCanDeactivate = this.canLeaveGuard.canDeactivate;
              this.canLeaveGuard.canDeactivate = () => true;

              this.isPilotStudy
                ? this.router
                    .navigate(['/ehr/allPatientsPilotStudy'])
                    .then(() => {
                      this.canLeaveGuard.canDeactivate = originalCanDeactivate;
                    })
                : this.router.navigate(['/ehr/inPatients']).then(() => {
                    this.canLeaveGuard.canDeactivate = originalCanDeactivate;
                  });
            } else {
              const originalCanDeactivate = this.canLeaveGuard.canDeactivate;
              this.canLeaveGuard.canDeactivate = () => true;
              this.router
                .navigate(['/ehr/profile'], {
                  queryParams: {
                    patientID: this.patientInfo?.id?.toString(),
                  },
                })
                .then(() => {
                  this.canLeaveGuard.canDeactivate = originalCanDeactivate;
                });
            }

            Swal.fire({
              text: `${this.translate.instant(
                'Visit was saved successfully'
              )}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant('Unable to save the visit')}!`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Cannot save the visit')}`,
          '',
          'info'
        );
      }
    });
  }

  getPrimaryDoctor(): IAssignedDoctor | undefined {
    return this.patientInfo?.assignedDoctors?.find(
      (doctor) => doctor.primaryDoctor
    );
  }
}
